
import { defineComponent } from "vue";

import { storeToken } from "@/utils/auth";

export default defineComponent({
    name: "CallbackView",
    created() {
        storeToken();
        this.$router.push({name: 'notebook'});
    }
});
