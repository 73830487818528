import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSearch = _resolveComponent("FormSearch")!
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tile, {
      title: _ctx.$t('export.export')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormSearch, {
          searchRequest: _ctx.searchRequest,
          loading: _ctx.searchLoading,
          hideSort: true,
          onUpdateSearchRequest: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchRequest = $event)),
          onSubmit: _withModifiers(_ctx.search, ["prevent"])
        }, null, 8, ["searchRequest", "loading", "onSubmit"]),
        (_ctx.searchLoading)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/loader.gif'),
              width: "50",
              height: "50"
            }, null, 8, _hoisted_2))
          : (_ctx.searchResponse)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('export.results', {total: _ctx.words.length})), 1),
                _createElementVNode("button", {
                  class: "btn btn-secondary my-2",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exportWords && _ctx.exportWords(...args))),
                  disabled: _ctx.exportLoading
                }, _toDisplayString(_ctx.$t('export.exportButton')), 9, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}