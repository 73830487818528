export interface SearchRequest {
    sourceLanguage: string,
    targetLanguage: string,
    contains: string,
    tags: string[],
    readingLevel: string,
    writingLevel: string,
    limit: Number | null,
    offset: Number,
    sortBy: string,
    sortOrder: string
};

export function encodeSearchRequest(searchRequest: SearchRequest): string {
    const urlParams = new URLSearchParams();
    urlParams.append('sourceLanguage', searchRequest.sourceLanguage);
    urlParams.append('targetLanguage', searchRequest.targetLanguage);
    if (searchRequest.contains !== '') {
        urlParams.append('contains', searchRequest.contains)
    }
    for (var i = 0; i < searchRequest.tags.length; i++) {
        urlParams.append('tags', searchRequest.tags[i]);
    }
    if (searchRequest.readingLevel !== '') {
        urlParams.append('readingLevel', searchRequest.readingLevel);
    }
    if (searchRequest.writingLevel !== '') {
        urlParams.append('writingLevel', searchRequest.writingLevel);
    }
    if (searchRequest.limit !== null) {
        urlParams.append('limit', searchRequest.limit.toString());
    }
    urlParams.append('offset', searchRequest.offset.toString());
    urlParams.append('sortBy', searchRequest.sortBy);
    urlParams.append('sortOrder', searchRequest.sortOrder);

    return urlParams.toString();
}

export interface SearchResponse {
    startOffset: number,
    endOffset: number,
    previousOffset: number | null,
    nextOffset: number | null,
    total: number,
    words: SearchResponseWord[]
};

export interface SearchResponseWord {
    id: number,
    word: string,
    meaning: string,
    tags: string[],
    known: boolean,
    readingScore: number,
    writingScore: number,
    readingLevel: string,
    writingLevel: string,
    readingProgress: number,
    writingProgress: number
}

export interface Word {
    id: number | null,
    sourceLanguage: string,
    targetLanguage: string,
    known: Boolean,
    word: string,
    meaning: string,
    tags: string[],
    mnemonicDevice: string,
    usage: string,
    notes: string,
    readingScore: number,
    writingScore: number,
    readingLevel: string,
    writingLevel: string,
    readingProgress: number,
    writingProgress: number,
    examples: Example[]
}

export interface Example {
    id: string,
    scenario: string,
    dialogue: Dialogue[]
}

export interface Dialogue {
    id: string,
    name: string,
    portrait: string | undefined,
    type: string,
    text: string,
    translation: string
}

export interface ListTagsRequest {
    sourceLanguage: string,
    targetLanguage: string
}

export function encodeListTagsRequest(listTagsRequest: ListTagsRequest) {
    const urlParams = new URLSearchParams();
    urlParams.append('sourceLanguage', listTagsRequest.sourceLanguage);
    urlParams.append('targetLanguage', listTagsRequest.targetLanguage);
    return urlParams.toString();
}

export interface GetReadingQuestionResponse {
    word: Word,
    question: string,
    example: Example,
    choices: string[]
}

export interface SubmitReadingAnswerRequest {
    question: string,
    example: Example,
    choices: string[],
    choice: string
}

export interface SubmitReadingAnswerResponse {
    correct: boolean,
    explanation: string,
    readingScore: number,
    readingLevel: string,
    readingProgress: number
}

export interface GetWritingQuestionResponse {
    word: Word,
    example: Example,
    missingIndex: number
}

export interface SubmitWritingAnswerRequest {
    example: Example,
    missingIndex: number
}

export interface SubmitWritingAnswerResponse {
    example: Example,
    correct: boolean,
    explanation: string,
    writingScore: number,
    writingLevel: string,
    writingProgress: number
}

export interface Question {
    word: number,
    type: string
}