
import { defineComponent } from "vue";
import Tile from "@/components/Tile.vue";

export default defineComponent({
    name: "LanguageSelectionView",
    components: {
        Tile,
    },
    data() {
        return {
            source: '',
            target: '',
            languages: [
                {name: "Abkhazian", code: "ab"},
                {name: "Afar", code: "aa"},
                {name: "Afrikaans", code: "af"},
                {name: "Albanian", code: "sq"},
                {name: "Amharic", code: "am"},
                {name: "Arabic", code: "ar"},
                {name: "Armenian", code: "hy"},
                {name: "Assamese", code: "as"},
                {name: "Aymara", code: "ay"},
                {name: "Azerbaijani", code: "az"},
                {name: "Bashkir", code: "ba"},
                {name: "Basque", code: "eu"},
                {name: "Bengali, Bangla", code: "bn"},
                {name: "Bhutani", code: "dz"},
                {name: "Bihari", code: "bh"},
                {name: "Bislama", code: "bi"},
                {name: "Breton", code: "br"},
                {name: "Bulgarian", code: "bg"},
                {name: "Burmese", code: "my"},
                {name: "Byelorussian", code: "be"},
                {name: "Cambodian", code: "km"},
                {name: "Catalan", code: "ca"},
                {name: "Chinese", code: "zh"},
                {name: "Corsican", code: "co"},
                {name: "Croatian", code: "hr"},
                {name: "Czech", code: "cs"},
                {name: "Danish", code: "da"},
                {name: "Dutch", code: "nl"},
                {name: "English, American", code: "en"},
                {name: "Esperanto", code: "eo"},
                {name: "Estonian", code: "et"},
                {name: "Faeroese", code: "fo"},
                {name: "Fiji", code: "fj"},
                {name: "Finnish", code: "fi"},
                {name: "French", code: "fr"},
                {name: "Frisian", code: "fy"},
                {name: "Gaelic (Scots Gaelic)", code: "gd"},
                {name: "Galician", code: "gl"},
                {name: "Georgian", code: "ka"},
                {name: "German", code: "de"},
                {name: "Greek", code: "el"},
                {name: "Greenlandic", code: "kl"},
                {name: "Guarani", code: "gn"},
                {name: "Gujarati", code: "gu"},
                {name: "Hausa", code: "ha"},
                {name: "Hebrew", code: "iw"},
                {name: "Hindi", code: "hi"},
                {name: "Hungarian", code: "hu"},
                {name: "Icelandic", code: "is"},
                {name: "Indonesian", code: "in"},
                {name: "Interlingua", code: "ia"},
                {name: "Interlingue", code: "ie"},
                {name: "Inupiak", code: "ik"},
                {name: "Irish", code: "ga"},
                {name: "Italian", code: "IT"},
                {name: "Japanese", code: "ja"},
                {name: "Javanese", code: "jw"},
                {name: "Kannada", code: "kn"},
                {name: "Kashmiri", code: "ks"},
                {name: "Kazakh", code: "kk"},
                {name: "Kinyarwanda", code: "rw"},
                {name: "Kirghiz", code: "ky"},
                {name: "Kirundi", code: "rn"},
                {name: "Korean", code: "ko"},
                {name: "Kurdish", code: "ku"},
                {name: "Laothian", code: "lo"},
                {name: "Latin", code: "la"},
                {name: "Latvian, Lettish", code: "lv"},
                {name: "Lingala", code: "ln"},
                {name: "Lithuanian", code: "lt"},
                {name: "Macedonian", code: "mk"},
                {name: "Malagasy", code: "mg"},
                {name: "Malay", code: "ms"},
                {name: "Malayalam", code: "ml"},
                {name: "Maltese", code: "mt"},
                {name: "Maori", code: "mi"},
                {name: "Marathi", code: "mr"},
                {name: "Moldavian", code: "mo"},
                {name: "Mongolian", code: "mn"},
                {name: "Nauru", code: "na"},
                {name: "Nepali", code: "ne"},
                {name: "Norwegian", code: "no"},
                {name: "Occitan", code: "oc"},
                {name: "Oriya", code: "or"},
                {name: "Oromo, Afan", code: "om"},
                {name: "Pashto, Pushto", code: "ps"},
                {name: "Persian", code: "fa"},
                {name: "Polish", code: "pl"},
                {name: "Portuguese", code: "pt"},
                {name: "Punjabi", code: "pa"},
                {name: "Quechua", code: "qu"},
                {name: "Rhaeto-Romance", code: "rm"},
                {name: "Romanian", code: "ro"},
                {name: "Russian", code: "ru"},
                {name: "Samoan", code: "sm"},
                {name: "Sangro", code: "sg"},
                {name: "Sanskrit", code: "sa"},
                {name: "Serbian", code: "sr"},
                {name: "Serbo-Croatian", code: "sh"},
                {name: "Sesotho", code: "st"},
                {name: "Setswana", code: "tn"},
                {name: "Shona", code: "sn"},
                {name: "Sindhi", code: "sd"},
                {name: "Singhalese", code: "si"},
                {name: "Siswati", code: "ss"},
                {name: "Slovak", code: "sk"},
                {name: "Slovenian", code: "sl"},
                {name: "Somali", code: "so"},
                {name: "Spanish", code: "es"},
                {name: "Sudanese", code: "su"},
                {name: "Swahili", code: "sw"},
                {name: "Swedish", code: "sv"},
                {name: "Tagalog", code: "tl"},
                {name: "Tajik", code: "tg"},
                {name: "Tamil", code: "ta"},
                {name: "Tatar", code: "tt"},
                {name: "Tegulu", code: "te"},
                {name: "Thai", code: "th"},
                {name: "Tibetan", code: "bo"},
                {name: "Tigrinya", code: "ti"},
                {name: "Tonga", code: "to"},
                {name: "Tsonga", code: "ts"},
                {name: "Turkish", code: "tr"},
                {name: "Turkmen", code: "tk"},
                {name: "Twi", code: "tw"},
                {name: "Ukrainian", code: "uk"},
                {name: "Urdu", code: "ur"},
                {name: "Uzbek", code: "uz"},
                {name: "Vietnamese", code: "vi"},
                {name: "Volapuk", code: "vo"},
                {name: "Welsh", code: "cy"},
                {name: "Wolof", code: "wo"},
                {name: "Xhosa", code: "xh"},
                {name: "Yiddish", code: "ji"},
                {name: "Yoruba", code: "yo"},
                {name: "Zulu", code: "zu"}
            ]
        }
    },
    methods: {
        selectLanguage() {
            localStorage.setItem('sourceLanguage', this.source);
            localStorage.setItem('targetLanguage', this.target);
            this.$i18n.locale = this.source;
            this.$router.push({name: 'notebook'})
        }
    }
});
