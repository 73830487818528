import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from 'vue-i18n'
import { messages } from '@/utils/i18n'

import 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'select2/dist/css/select2.min.css';
import 'select2'
import '@/styles/styles.css';

const i18n = createI18n({
    locale: localStorage.getItem('sourceLanguage') || 'en',
    fallbackLocale: 'en',
    messages: messages,
    tag: ''
})

const app = createApp(App);
app.use(router);
app.use(i18n);
app.mount('#app');