import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row my-3" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormWord = _resolveComponent("FormWord")!
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tile, null, {
      default: _withCtx(() => [
        (_ctx.getLoading)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/loader.gif'),
              width: "50",
              height: "50"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.word !== null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.word.word), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "btn btn-secondary",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({name: 'word', params: { id: _ctx.$route.params.id }})), ["prevent"]))
                  }, _toDisplayString(_ctx.$t('notebook.view')), 1)
                ])
              ]),
              (_ctx.word !== null)
                ? (_openBlock(), _createBlock(_component_FormWord, {
                    key: 0,
                    word: _ctx.word,
                    loading: _ctx.updateLoading,
                    onUpdateWord: _cache[1] || (_cache[1] = ($event: any) => (_ctx.word = $event)),
                    onSubmit: _withModifiers(_ctx.updateWord, ["prevent"])
                  }, null, 8, ["word", "loading", "onSubmit"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}