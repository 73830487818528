
import { 
    PropType,
    defineComponent
} from "vue";
import Tile from '@/components/Tile.vue';
import InputMessage from '@/components/InputMessage.vue';

import { deepCopy } from '@/utils/copy';
import type { Dialogue } from '@/types';

export default defineComponent({
    name: "InputDialogue",
    components: {
        Tile,
        InputMessage,
    },
    props: {
        dialogue: Object as PropType<Dialogue>
    },
    data() {
        return {
            sourceLang: localStorage.getItem('sourceLanguage'),
            targetLang: localStorage.getItem('targetLanguage'),
            localDialogue: deepCopy(this.dialogue) as Dialogue
        }
    },
    watch: {
        localDialogue: {
            handler(val) {
                this.$emit('updateDialogue', val);
            },
            deep: true
        }
    }
});
