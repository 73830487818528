import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e35a480"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialogue = _resolveComponent("Dialogue")!
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createBlock(_component_Tile, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.example.scenario), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.example.dialogue, (dialogue, idx) => {
        return (_openBlock(), _createBlock(_component_Dialogue, {
          key: idx,
          dialogue: dialogue
        }, null, 8, ["dialogue"]))
      }), 128))
    ]),
    _: 1
  }))
}