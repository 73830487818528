
import { 
    PropType,
    defineComponent
} from "vue";
import { GetReadingQuestionResponse, SubmitReadingAnswerRequest } from "@/types";

import Example from '@/components/Example.vue';
import LevelBadge from '@/components/LevelBadge.vue';

export default defineComponent({
    name: 'ReadingQuestion',
    components: {
        Example,
        LevelBadge
    },
    props: {
        question: {
            type: Object as PropType<GetReadingQuestionResponse>,
            required: true
        },
        answerLoading: {
            type: Boolean,
            required: true
        },
        answered: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            answer: {
                question: this.question.question,
                example: this.question.example,
                choices: this.question.choices,
                choice: ''
            } as SubmitReadingAnswerRequest
        }
    }
});
