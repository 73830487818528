export const messages = {
    en: {
        nav: {
            notebook: 'Notebook',
            practice: 'Practice',
            import: 'Import',
            export: 'Export',
            logout: 'Logout',
            delete: 'Delete',
            language: 'Language'
        },
        notebook: {
            add: 'Add',
            contains: 'Contains',
            search: 'Search',
            result: 'Result',
            results: '{startOffset}..{endOffset} from {total} results',
            save: 'Save',
            sortBy: 'Sort By',
            sortOrder: 'Sort Order',
            ascending: 'Ascending',
            descending: 'Descending',
            addExample: 'Add example',
            generateExample: 'Generate example (AI)',
            addDialogue: 'Add dialogue',
            practice: 'Practice',
            view: 'View',
            edit: 'Edit',
            delete: 'Delete'
        },
        word: {
            word: 'Word',
            meaning: 'Meaning',
            tags: 'Tags',
            level: 'Level',
            readingLevel: 'Reading Level',
            writingLevel: 'Writing Level',
            type: 'Type',
            notes: 'Notes',
            mnemonicDevice: 'Mnemonic Device',
            usage: 'Usage',
            scenario: 'Scenario',
            name: 'Name',
            speech: 'Speech',
            thought: 'Thought',
            silhouette: 'Silhouette',
            female: 'Female',
            male: 'Male',
            known: 'I already know it',
            vocab: 'Vocab',
            grammar: 'Grammar',
            dialogue: 'Dialogue',
            translation: 'Translation',
            examples: 'Examples',
            seeTranslation: 'See translation',
            vocabTag: 'vocab',
            grammarTag: 'grammar'
        },
        level: {
            all: 'All',
            beginner: 'Beginner',
            intermediate: 'Intermediate',
            expert: 'Expert'
        },
        practice: {
            practice: 'Practice',
            type: 'Type',
            all: 'All',
            reading: 'Reading',
            writing: 'Writing',
            results: '{total} results',
            remaining: '{remaining} questions remaining',
            fillBlank: 'Fill in the blank dialogue using {word}',
            answer: 'Answer',
            answerButton: 'Answer',
            saveExample: 'Save example',
            next: 'Next',
            practiceAgain: 'Practice again',
            correct: 'Correct',
            incorrect: 'Incorrect'
        },
        import: {
            import: 'Import',
            explanation: 'You can import two types of files.',
            example_1: 'Exports from Tango Chou (the file type is .json)',
            example_2: 'Spreadsheet files (the file type is .tsv, see the sample):',
            sample: 'sample',
            importButton: 'Import'
        },
        export: {
            export: 'Export',
            results: '{total} results',
            exportButton: 'Export'
        },
        delete: {
            delete: 'Delete',
            results: '{total} results',
            deleteButton: 'Delete',
            confirmation: 'Are you sure you want to delete these words?'
        }
    },
    ja: {
        nav: {
            notebook: '単語帳',
            practice: '練習',
            import: 'インポート',
            export: 'エクスポート',
            logout: 'ログアウト',
            delete: '削除',
            language: '言語'
        },
        notebook: {
            add: '追加',
            contains: 'キーワード',
            search: '検索',
            result: '検索結果',
            results: '{startOffset}..{endOffset} / 全{total}件',
            save: '保存',
            sortBy: 'ソート方法',
            sortOrder: 'ソート順序',
            ascending: '昇順',
            descending: '降順',
            addExample: '例を追加',
            generateExample: '例を生成 (AIで)',
            addDialogue: 'メッセージを追加',
            practice: '練習',
            view: '見る',
            edit: '編集',
            delete: '削除'
        },
        word: {
            word: '言葉',
            meaning: '意味',
            tags: 'タグ',
            level: 'レベル',
            readingLevel: 'リーディング・レベル',
            writingLevel: 'ライティング・レベル',
            type: 'タイプ',
            notes: 'メモ',
            mnemonicDevice: '覚え方',
            usage: '使い方',
            scenario: 'シチュエーション',
            name: '名前',
            speech: '台詞',
            thought: '思考',
            silhouette: '人影',
            female: '女性',
            male: '男性',
            known: 'もう知っている',
            vocab: '単語',
            grammar: '文法',
            dialogue: 'メッセージ',
            translation: '翻訳',
            examples: '例',
            seeTranslation: '翻訳を見る',
            vocabTag: '単語',
            grammarTag: '文法'
        },
        level: {
            all: '全部',
            beginner: '初心者',
            intermediate: '中級',
            expert: '高級'
        },
        practice: {
            practice: '練習',
            type: 'タイプ',
            all: '全部',
            reading: 'リーディング',
            writing: 'ライティング',
            results: '全{total}件',
            remaining: '残り{remaining}問',
            fillBlank: '{word}を使って空欄を埋めてください',
            answer: '答え',
            answerButton: '答える',
            saveExample: '例を保存',
            next: '次',
            practiceAgain: 'また練習',
            correct: '正解',
            incorrect: '不正解'
        },
        import: {
            import: 'インポート',
            explanation: '2種類のファイルをインポートできます。',
            example_1: 'Tango Chouからのエクスポート (ファイルタイプは.json)',
            example_2: 'スプレッドシートのファイル (ファイルタイプは.tsv, サンプルを見る):',
            sample: 'サンプル',
            importButton: 'インポートする'
        },
        export: {
            export: 'エクスポート',
            results: '全{total}件',
            exportButton: 'エクスポートする'
        },
        delete: {
            delete: '削除',
            results: '全{total}件',
            deleteButton: '削除する',
            confirmation: 'この言葉を削除してもよろしいですか？'
        }
    }
};