
import { defineComponent } from "vue";

import Tile from '@/components/Tile.vue';
import InputPracticeType from '@/components/InputPracticeType.vue';
import Practice from '@/components/Practice.vue';

import type { Word } from '@/types';
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: "PracticeSingleView",
    components: {
        Tile,
        InputPracticeType,
        Practice
    },
    created() {
        this.getWord();
    },
    data() {
        return {
            getLoading: false,
            word: null as Word | null,
            words: [] as number[],
            type: 'all',
            practiceStarted: false,
            practiceKey: 0,
        }
    },
    methods: {
        getWord() {
            this.getLoading = true;

            fetch(
                getEndpoint() + '/words/' + this.$route.params.id,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        const word = await response.json();
                        this.word = word;
                        this.words = [word.id];
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load word. Please try again later.');
                        this.$router.push({name: 'word', params: { id: this.$route.params.id }})
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load word. Please try again later.');
                    this.$router.push({name: 'word', params: { id: this.$route.params.id }});
                }
            ).finally(
                () => {
                    this.getLoading = false;
                }
            )
        },
        startPractice() {
            this.practiceStarted = true;
            this.practiceKey = (this.practiceKey + 1) % 2;
        }
    }
});
