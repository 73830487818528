
import { defineComponent } from "vue";
import Tile from "@/components/Tile.vue";
import FormSearch from '@/components/FormSearch.vue';
import LevelBadge from '@/components/LevelBadge.vue';

import {
    getAuthorizationHeader,
    getEndpoint,
    logout
} from '@/utils/auth';
import {
    SearchRequest,
    SearchResponse,
    encodeSearchRequest
} from '@/types';

export default defineComponent({
    name: "NotebookView",
    components: {
        Tile,
        FormSearch,
        LevelBadge
    },
    created() {
        this.submitSearch();
    },
    data() {
        return {
            searchRequest: {
                sourceLanguage: localStorage.getItem('sourceLanguage'),
                targetLanguage: localStorage.getItem('targetLanguage'),
                contains: '',
                tags: [],
                readingLevel: '',
                writingLevel: '',
                limit: 50,
                offset: 0,
                sortBy: 'Word',
                sortOrder: 'ASC'
            } as SearchRequest,
            currentSearchRequest: {
                sourceLanguage: localStorage.getItem('sourceLanguage'),
                targetLanguage: localStorage.getItem('targetLanguage'),
                contains: '',
                tags: [],
                readingLevel: '',
                writingLevel: '',
                limit: 50,
                offset: 0,
                sortBy: 'Word',
                sortOrder: 'ASC'
            } as SearchRequest,
            searchLoading: false as boolean,
            searchResponse: null as SearchResponse | null
        }
    },
    methods: {
        submitSearch() {
            this.currentSearchRequest = this.searchRequest;
            this.search(0);
        },
        search(offset: Number) {
            this.searchResponse = null;
            this.searchLoading = true;

            this.currentSearchRequest.offset = offset;

            fetch(
                getEndpoint() + '/words?' + encodeSearchRequest(this.currentSearchRequest),
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.searchResponse = await response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load words. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load words. Please try again later.');
                }
            ).finally(
                () => {
                    this.searchLoading = false;
                }
            );
        }
    }
});
