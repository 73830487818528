
import { defineComponent } from "vue";

export default defineComponent({
    name: "LevelBadge",
    props: {
        icon: String,
        score: Number,
        level: String,
        progress: Number
    }
});
