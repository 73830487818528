import auth0 from "auth0-js";

// Auth configuration.
let authConfig = {
    domain: 'tangochou-prod.us.auth0.com',
    clientID: '66LMALxVEaQsBhEgj7erir2nVA3QavZl',
    redirectUri: 'https://tangochou.io/callback',
    responseType: 'token id_token',
    scope: 'openid profile'
};
let audience = 'https://tango-chou';
let afterLogoutUrl = 'https://tangochou.io';
if (process.env.NODE_ENV === 'development') {
    authConfig = {
        domain: 'tangochou-dev.us.auth0.com',
        clientID: 'p8fnIeRRqxqaPzMGlA67pffcHu03tFtP',
        redirectUri: 'http://localhost:3000/callback',
        responseType: 'token id_token',
        scope: 'openid profile'
    } 
    audience = 'https://tango-chou';
    afterLogoutUrl = 'http://localhost:3000';
}
const auth0Client = new auth0.WebAuth(authConfig);

export async function login() {
    if (localStorage.getItem('authToken') !== null) {
        return;
    }
    
    await auth0Client.authorize({
        audience: audience
    });
}

export function storeToken() {
    const fragment = window.location.hash.substr(1); // Remove the leading #
    const urlParams = new URLSearchParams(fragment);
    
    const authToken = urlParams.get('access_token');
    if (authToken) {
        localStorage.setItem('authToken', authToken);
    }
}

export async function logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('sourceLanguage');
    localStorage.removeItem('targetLanguage');
    
    await auth0Client.logout({
        returnTo: afterLogoutUrl
    });
}

export function getEndpoint(): string {
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8080'
    }

    return 'https://tangochou.io';
}

export function getAuthorizationHeader(): string {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
        return '';
    }

    return 'Bearer ' + authToken;
}