
import { defineComponent } from "vue";

export default defineComponent({
    name: 'Message',
    props: {
        type: String,
        text: String,
        translation: String,
    },
    mounted() {
        this.simulateUserInteraction();
        this.loadVoices();
    },
    data() {
        return {
            showTranslation: false,
            voices: [] as Array<any>
        }
    },
    methods: {
        loadVoices() {
            window.speechSynthesis.onvoiceschanged = () => {
                this.voices = window.speechSynthesis.getVoices();
            };
        },
        simulateUserInteraction() {
            const button = document.getElementById('simulate-click');
            if (button === null) {
                return;
            }
            const event = new Event('click', { bubbles: true, cancelable: true });
            button.dispatchEvent(event);
        },
        toggleTranslation() {
            this.showTranslation = !this.showTranslation;
        },
        playAudio() {
            if (this.voices.length == 0) {
                this.voices = window.speechSynthesis.getVoices();
                console.log(window.speechSynthesis.getVoices());
            }

            const utterance = new SpeechSynthesisUtterance(this.text);
            const selectedVoice = this.voices.find((voice) => voice.lang.substring(0, 2) === localStorage.getItem('targetLanguage'));

            if (selectedVoice) {
                utterance.voice = selectedVoice;
                window.speechSynthesis.speak(utterance);
            }
        }
    }
})
