import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-579e94ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = { class: "col-12 col-lg-auto mx-2 my-2 text-center" }
const _hoisted_3 = {
  key: 0,
  class: "my-2"
}
const _hoisted_4 = { class: "nameplate" }
const _hoisted_5 = { class: "my-2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "col-12 col-lg mx-2 my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.dialogue.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.dialogue.name), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.dialogue.portrait)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "portrait",
              src: require(`@/assets/portraits/${_ctx.dialogue.portrait}.png`)
            }, null, 8, _hoisted_6))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "portrait",
              src: require('@/assets/portraits/s_1.png')
            }, null, 8, _hoisted_7))
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Message, {
        type: _ctx.dialogue.type,
        text: _ctx.dialogue.text,
        translation: _ctx.dialogue.translation
      }, null, 8, ["type", "text", "translation"])
    ])
  ]))
}