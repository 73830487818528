import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78264bda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "my-3 text-center" }
const _hoisted_3 = {
  class: "btn btn-secondary add-btn",
  href: "/word/add",
  target: "_blank"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row my-3" }
const _hoisted_7 = {
  class: "col",
  style: {"text-align":"left"}
}
const _hoisted_8 = {
  class: "col",
  style: {"text-align":"right"}
}
const _hoisted_9 = { class: "table-responsive" }
const _hoisted_10 = { class: "table table-dark table-striped" }
const _hoisted_11 = {
  class: "text-center",
  scope: "col"
}
const _hoisted_12 = {
  class: "text-center",
  scope: "col"
}
const _hoisted_13 = {
  class: "text-center",
  scope: "col"
}
const _hoisted_14 = {
  class: "text-center",
  scope: "col"
}
const _hoisted_15 = {
  class: "text-center",
  scope: "row"
}
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "text-center" }
const _hoisted_18 = { class: "text-center" }
const _hoisted_19 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSearch = _resolveComponent("FormSearch")!
  const _component_Tile = _resolveComponent("Tile")!
  const _component_LevelBadge = _resolveComponent("LevelBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, "+ " + _toDisplayString(_ctx.$t('notebook.add')), 1)
    ]),
    _createVNode(_component_Tile, {
      title: _ctx.$t('notebook.search')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormSearch, {
          searchRequest: _ctx.searchRequest,
          loading: _ctx.searchLoading,
          onUpdateSearchRequest: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchRequest = $event)),
          onSubmit: _withModifiers(_ctx.submitSearch, ["prevent"])
        }, null, 8, ["searchRequest", "loading", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title"]),
    (_ctx.searchLoading || _ctx.searchResponse)
      ? (_openBlock(), _createBlock(_component_Tile, {
          key: 0,
          title: _ctx.$t('notebook.result')
        }, {
          default: _withCtx(() => [
            (_ctx.searchLoading)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: require('@/assets/loader.gif'),
                  width: "50",
                  height: "50"
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.searchResponse !== null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, "Showing " + _toDisplayString(_ctx.$t('notebook.results', {startOffset: _ctx.searchResponse.startOffset, endOffset: _ctx.searchResponse.endOffset, total: _ctx.searchResponse.total})), 1),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (_ctx.searchResponse.previousOffset !== null)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: "#",
                            class: "link",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.search(_ctx.searchResponse.previousOffset)), ["prevent"]))
                          }, "Previous"))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      (_ctx.searchResponse.nextOffset !== null)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: "#",
                            class: "link",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.search(_ctx.searchResponse.nextOffset)), ["prevent"]))
                          }, "Next"))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("table", _hoisted_10, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.$t('word.word')), 1),
                          _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$t('word.meaning')), 1),
                          _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$t('word.tags')), 1),
                          _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t('word.level')), 1)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResponse.words, (word, idx) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: idx,
                            style: {"font-size":"20px"}
                          }, [
                            _createElementVNode("th", _hoisted_15, [
                              _createElementVNode("a", {
                                class: "link no-decoration",
                                href: '/word/' + word.id,
                                target: "_blank"
                              }, _toDisplayString(word.word), 9, _hoisted_16)
                            ]),
                            _createElementVNode("td", _hoisted_17, _toDisplayString(word.meaning), 1),
                            _createElementVNode("td", _hoisted_18, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(word.tags, (tag, tagIdx) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: tagIdx,
                                  class: "badge rounded-pill text-bg-secondary mx-1"
                                }, _toDisplayString(tag), 1))
                              }), 128))
                            ]),
                            _createElementVNode("td", _hoisted_19, [
                              _createVNode(_component_LevelBadge, {
                                icon: "📖",
                                score: word.readingScore,
                                level: word.readingLevel,
                                progress: word.readingProgress
                              }, null, 8, ["score", "level", "progress"]),
                              _createVNode(_component_LevelBadge, {
                                icon: "✍",
                                score: word.writingScore,
                                level: word.writingLevel,
                                progress: word.writingProgress
                              }, null, 8, ["score", "level", "progress"])
                            ])
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ]))
}