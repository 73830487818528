import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-390030d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "level-text"
}
const _hoisted_3 = {
  key: 1,
  class: "level-text"
}
const _hoisted_4 = {
  key: 2,
  class: "level-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "level badge rounded-pill bg-secondary mx-1",
    title: _ctx.score
  }, [
    _createElementVNode("span", {
      class: "level-progress",
      style: _normalizeStyle({
                '--width': _ctx.progress + '%',
                '--border-top-right-radius': _ctx.progress === 100 ? '800px' : 'none',
                '--border-bottom-right-radius': _ctx.progress === 100 ? '800px' : 'none'
            })
    }, [
      _createTextVNode(_toDisplayString(_ctx.icon) + " ", 1),
      (_ctx.level === 'expert')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('level.expert')), 1))
        : (_ctx.level === 'intermediate')
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('level.intermediate')), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('level.beginner')), 1))
    ], 4)
  ], 8, _hoisted_1))
}