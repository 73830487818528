import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43b9f1b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row my-3" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col auto" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12 col-lg-auto my-2" }
const _hoisted_9 = { class: "col-12 col-lg-auto my-2" }
const _hoisted_10 = { class: "col-12 col-lg-auto my-2" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "my-3" }
const _hoisted_13 = { class: "my-3" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = { class: "my-3" }
const _hoisted_16 = {
  key: 0,
  class: "my-3"
}
const _hoisted_17 = { class: "value allow-lines" }
const _hoisted_18 = {
  key: 1,
  class: "my-3"
}
const _hoisted_19 = { class: "value allow-lines" }
const _hoisted_20 = {
  key: 2,
  class: "my-3"
}
const _hoisted_21 = { class: "value allow-lines" }
const _hoisted_22 = {
  key: 3,
  class: "my-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelBadge = _resolveComponent("LevelBadge")!
  const _component_Example = _resolveComponent("Example")!
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tile, null, {
      default: _withCtx(() => [
        (_ctx.getLoading)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/loader.gif'),
              width: "50",
              height: "50"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.word !== null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.word.word), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("button", {
                        class: "btn btn-primary",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({name: 'practice-single', params: { id: _ctx.$route.params.id }})), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('notebook.practice')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("button", {
                        class: "btn btn-secondary",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$router.push({name: 'word-edit', params: { id: _ctx.$route.params.id }})), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('notebook.edit')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("button", {
                        class: "btn btn-secondary",
                        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteWord && _ctx.deleteWord(...args)), ["prevent"])),
                        disabled: _ctx.deleteLoading
                      }, _toDisplayString(_ctx.$t('notebook.delete')), 9, _hoisted_11)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_LevelBadge, {
                  icon: "📖",
                  score: _ctx.word.readingScore,
                  level: _ctx.word.readingLevel,
                  progress: _ctx.word.readingProgress
                }, null, 8, ["score", "level", "progress"]),
                _createVNode(_component_LevelBadge, {
                  icon: "✍",
                  score: _ctx.word.writingScore,
                  level: _ctx.word.writingLevel,
                  progress: _ctx.word.writingProgress
                }, null, 8, ["score", "level", "progress"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('word.meaning')), 1)
                ]),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.word.meaning), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('word.tags')), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.word.tags, (tag, tagIdx) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: tagIdx,
                    class: "badge rounded-pill text-bg-secondary mx-1"
                  }, _toDisplayString(tag), 1))
                }), 128))
              ]),
              (_ctx.word.notes)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("div", null, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('word.notes')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.word.notes), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.word.mnemonicDevice)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("div", null, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('word.mnemonicDevice')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.word.mnemonicDevice), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.word.usage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("div", null, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('word.usage')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.word.usage), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.word.examples.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("div", null, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('word.examples')), 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.word.examples, (example, idx) => {
                      return (_openBlock(), _createBlock(_component_Example, {
                        key: idx,
                        example: example
                      }, null, 8, ["example"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}