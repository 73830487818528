import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b14cbe4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-lg-4 text-center my-auto" }
const _hoisted_3 = {
  class: "my-2 text-center",
  "data-bs-theme": "dark"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "my-2 text-center" }
const _hoisted_6 = { value: "s_1" }
const _hoisted_7 = { value: "f_1" }
const _hoisted_8 = { value: "f_2" }
const _hoisted_9 = { value: "f_3" }
const _hoisted_10 = { value: "f_4" }
const _hoisted_11 = { value: "m_1" }
const _hoisted_12 = { value: "m_2" }
const _hoisted_13 = { value: "m_3" }
const _hoisted_14 = { value: "m_4" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "col-12 col-lg-8 my-auto" }
const _hoisted_17 = { class: "my-2 center" }
const _hoisted_18 = { value: "speech" }
const _hoisted_19 = { value: "thought" }
const _hoisted_20 = { class: "my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMessage = _resolveComponent("InputMessage")!
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createBlock(_component_Tile, { class: "my-1" }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn-close btn-close-white float-left",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localDialogue.name) = $event)),
              placeholder: _ctx.$t('word.name')
            }, null, 8, _hoisted_4), [
              [_vModelText, _ctx.localDialogue.name]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("select", {
              class: "mx-1",
              "data-bs-theme": "dark",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localDialogue.portrait) = $event))
            }, [
              _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$t('word.silhouette')), 1),
              _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.$t('word.female')) + " 1", 1),
              _createElementVNode("option", _hoisted_8, _toDisplayString(_ctx.$t('word.female')) + " 2", 1),
              _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.$t('word.female')) + " 3", 1),
              _createElementVNode("option", _hoisted_10, _toDisplayString(_ctx.$t('word.female')) + " 4", 1),
              _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.$t('word.male')) + " 1", 1),
              _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.$t('word.male')) + " 2", 1),
              _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.$t('word.male')) + " 3", 1),
              _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.$t('word.male')) + " 4", 1)
            ], 512), [
              [_vModelSelect, _ctx.localDialogue.portrait]
            ]),
            _createElementVNode("img", {
              class: "portrait mx-1",
              src: require(`@/assets/portraits/${_ctx.localDialogue.portrait}.png`)
            }, null, 8, _hoisted_15)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('word.type')), 1),
            _withDirectives(_createElementVNode("select", {
              class: "mx-1",
              "data-bs-theme": "dark",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localDialogue.type) = $event))
            }, [
              _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$t('word.speech')), 1),
              _createElementVNode("option", _hoisted_19, _toDisplayString(_ctx.$t('word.thought')), 1)
            ], 512), [
              [_vModelSelect, _ctx.localDialogue.type]
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_InputMessage, {
              text: _ctx.localDialogue.text,
              onUpdateText: _cache[4] || (_cache[4] = ($event: any) => (_ctx.localDialogue.text=$event)),
              translation: _ctx.localDialogue.translation,
              onUpdateTranslation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.localDialogue.translation=$event)),
              type: _ctx.localDialogue.type
            }, null, 8, ["text", "translation", "type"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}