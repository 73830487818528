import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b10468c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "my-2"
}
const _hoisted_2 = {
  for: "word",
  class: "form-label"
}
const _hoisted_3 = { class: "my-2" }
const _hoisted_4 = {
  for: "known",
  class: "form-check-label"
}
const _hoisted_5 = { class: "my-2" }
const _hoisted_6 = {
  for: "meaning",
  class: "form-label"
}
const _hoisted_7 = { class: "my-2" }
const _hoisted_8 = {
  for: "source",
  class: "form-label"
}
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "my-2" }
const _hoisted_12 = {
  for: "form-word-tag-select",
  class: "form-label"
}
const _hoisted_13 = {
  id: "form-word-tag-select",
  class: "form-select tag-select",
  "data-bs-theme": "dark",
  multiple: "multiple"
}
const _hoisted_14 = ["selected"]
const _hoisted_15 = { class: "my-2" }
const _hoisted_16 = {
  for: "notes",
  class: "form-label"
}
const _hoisted_17 = { class: "my-2" }
const _hoisted_18 = {
  for: "mnemonic-device",
  class: "form-label"
}
const _hoisted_19 = { class: "my-2" }
const _hoisted_20 = {
  for: "usage",
  class: "form-label"
}
const _hoisted_21 = { class: "my-2" }
const _hoisted_22 = { class: "my-3" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = { class: "my-5" }
const _hoisted_25 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputExample = _resolveComponent("InputExample")!

  return (_openBlock(), _createElementBlock("form", null, [
    (!_ctx.localWord.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, "*" + _toDisplayString(_ctx.$t('word.word')) + " (" + _toDisplayString(_ctx.targetLang) + ")", 1),
          _withDirectives(_createElementVNode("input", {
            id: "word",
            class: "form-control",
            "data-bs-theme": "dark",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localWord.word) = $event)),
            type: "text",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.localWord.word]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        id: "known",
        class: "form-check-input mx-1",
        type: "checkbox",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localWord.known) = $event))
      }, null, 512), [
        [_vModelCheckbox, _ctx.localWord.known]
      ]),
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('word.known')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, "*" + _toDisplayString(_ctx.$t('word.meaning')) + " (" + _toDisplayString(_ctx.sourceLang) + ")", 1),
      _withDirectives(_createElementVNode("textarea", {
        id: "meaning",
        class: "form-control",
        "data-bs-theme": "dark",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localWord.meaning) = $event)),
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.localWord.meaning]
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("label", _hoisted_8, "*" + _toDisplayString(_ctx.$t('word.type')), 1),
      _withDirectives(_createElementVNode("select", {
        class: "form-select",
        "data-bs-theme": "dark",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.wordType) = $event)),
        required: ""
      }, [
        _createElementVNode("option", {
          value: _ctx.$t('word.vocabTag')
        }, _toDisplayString(_ctx.$t('word.vocab')), 9, _hoisted_9),
        _createElementVNode("option", {
          value: _ctx.$t('word.grammarTag')
        }, _toDisplayString(_ctx.$t('word.grammar')), 9, _hoisted_10)
      ], 512), [
        [_vModelSelect, _ctx.wordType]
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('word.tags')) + " (" + _toDisplayString(_ctx.sourceLang) + ")", 1),
      _createElementVNode("select", _hoisted_13, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagOptions, (tag, idx) => {
          return (_openBlock(), _createElementBlock("option", {
            key: idx,
            selected: _ctx.word.tags.includes(tag)
          }, _toDisplayString(tag), 9, _hoisted_14))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('word.notes')) + " (" + _toDisplayString(_ctx.sourceLang) + ")", 1),
      _withDirectives(_createElementVNode("textarea", {
        id: "notes",
        class: "form-control",
        "data-bs-theme": "dark",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localWord.notes) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localWord.notes]
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('word.mnemonicDevice')) + " (" + _toDisplayString(_ctx.sourceLang) + ")", 1),
      _withDirectives(_createElementVNode("textarea", {
        id: "mnemonic-device",
        class: "form-control",
        "data-bs-theme": "dark",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localWord.mnemonicDevice) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localWord.mnemonicDevice]
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('word.usage')) + " (" + _toDisplayString(_ctx.sourceLang) + ")", 1),
      _withDirectives(_createElementVNode("textarea", {
        id: "usage",
        class: "form-control",
        "data-bs-theme": "dark",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localWord.usage) = $event))
      }, null, 512), [
        [_vModelText, _ctx.localWord.usage]
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localWord.examples, (example, idx) => {
        return (_openBlock(), _createBlock(_component_InputExample, {
          key: example.id,
          example: example,
          onUpdateExample: ($event: any) => (_ctx.localWord.examples[idx] = $event),
          onClose: ($event: any) => (_ctx.localWord.examples.splice(idx, 1))
        }, null, 8, ["example", "onUpdateExample", "onClose"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("button", {
        class: "btn btn-secondary add-btn mx-2 my-2",
        onClick: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addExample && _ctx.addExample(...args)), ["prevent"]))
      }, "+ " + _toDisplayString(_ctx.$t('notebook.addExample')), 1),
      _createElementVNode("button", {
        class: "btn btn-secondary add-btn mx-2 my-2",
        onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.generateExample && _ctx.generateExample(...args)), ["prevent"])),
        disabled: _ctx.generateExampleLoading || !_ctx.localWord.word || !_ctx.localWord.meaning
      }, "+ " + _toDisplayString(_ctx.$t('notebook.generateExample')), 9, _hoisted_23)
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("button", {
        type: "submit",
        class: "btn btn-secondary",
        disabled: _ctx.loading
      }, _toDisplayString(_ctx.$t('notebook.save')), 9, _hoisted_25)
    ])
  ]))
}