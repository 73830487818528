import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "form-label" }
const _hoisted_3 = {
  selected: "",
  value: "all"
}
const _hoisted_4 = { value: "reading" }
const _hoisted_5 = { value: "writing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('practice.type')), 1),
    _withDirectives(_createElementVNode("select", {
      class: "form-select",
      "data-bs-theme": "dark",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localType) = $event))
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.$t('practice.all')), 1),
      _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.$t('practice.reading')), 1),
      _createElementVNode("option", _hoisted_5, _toDisplayString(_ctx.$t('practice.writing')), 1)
    ], 512), [
      [_vModelSelect, _ctx.localType]
    ])
  ]))
}