import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3367e4b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "my-3 text-center" }
const _hoisted_3 = { class: "my-2" }
const _hoisted_4 = {
  key: 0,
  class: "correct"
}
const _hoisted_5 = {
  key: 1,
  class: "incorrect"
}
const _hoisted_6 = {
  key: 0,
  class: "my-2"
}
const _hoisted_7 = { class: "my-3" }
const _hoisted_8 = { class: "explanation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelBadge = _resolveComponent("LevelBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.answer.correct)
          ? (_openBlock(), _createElementBlock("strong", _hoisted_4, _toDisplayString(_ctx.$t('practice.correct')), 1))
          : (_openBlock(), _createElementBlock("strong", _hoisted_5, _toDisplayString(_ctx.$t('practice.incorrect')), 1))
      ]),
      (_ctx.answer.correct)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_LevelBadge, {
              class: "my-1",
              icon: "📖",
              score: _ctx.question.word.readingScore,
              level: _ctx.question.word.readingLevel,
              progress: _ctx.question.word.readingProgress
            }, null, 8, ["score", "level", "progress"]),
            _createTextVNode(" → "),
            _createVNode(_component_LevelBadge, {
              class: "my-1",
              icon: "📖",
              score: _ctx.answer.readingScore,
              level: _ctx.answer.readingLevel,
              progress: _ctx.answer.readingProgress
            }, null, 8, ["score", "level", "progress"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.answer.explanation), 1)
    ])
  ]))
}