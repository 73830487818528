
import { defineComponent } from "vue";

import Tile from '@/components/Tile.vue';
import FormSearch from '@/components/FormSearch.vue';
import { SearchRequest, SearchResponse, encodeSearchRequest } from "@/types";
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: 'ExportView',
    components: {
        Tile,
        FormSearch,
    },
    data() {
        return {
            searchRequest: {
                sourceLanguage: localStorage.getItem('sourceLanguage'),
                targetLanguage: localStorage.getItem('targetLanguage'),
                contains: '',
                tags: [],
                readingLevel: '',
                writingLevel: '',
                limit: 100,
                offset: 0,
                sortBy: 'Word',
                sortOrder: 'ASC'
            } as SearchRequest,
            searchLoading: false as boolean,
            searchResponse: null as SearchResponse | null,
            words: [] as number[],
            exportLoading: false
        }
    },
    methods: {
        search() {
            this.words = [];
            this.searchResponse = null;
            this.searchLoading = true;

            fetch(
                getEndpoint() + '/words?' + encodeSearchRequest(this.searchRequest),
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.searchResponse = await response.json()
                        for (var i = 0; i < this.searchResponse!.words.length; i++) {
                            this.words.push(this.searchResponse!.words[i].id);
                        }
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load words. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load words. Please try again later.');
                }
            ).finally(
                () => {
                    this.searchLoading = false;
                }
            );
        },
        exportWords() {
            this.exportLoading = true;

            fetch(
                getEndpoint() + '/words/export?sourceLanguage=' + localStorage.getItem('sourceLanguage') + '&targetLanguage=' + localStorage.getItem('targetLanguage'),
                {
                    method: 'POST',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.words)
                }
            ).then(
                async response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error exporting. Please try again later.');
                    }
                }
            ).then(
                data => {
                    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.download = `tangochou-export-${new Date().toISOString().slice(0, 19).replace(/[-T:]/g, '')}.json`;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            ).catch(
                error => {
                    alert('Error exporting. Please try again later.');
                }
            ).finally(
                () => {
                    this.exportLoading = false;
                }
            )
        }
    }
});
