
import { 
    PropType,
    defineComponent
} from "vue";

import LevelBadge from '@/components/LevelBadge.vue';
import { GetReadingQuestionResponse, SubmitReadingAnswerResponse } from "@/types";

export default defineComponent({
    Name: 'ReadingAnswer',
    components: {
        LevelBadge
    },
    props: {
        question: {
            type: Object as PropType<GetReadingQuestionResponse>,
            required: true
        },
        answer: {
            type: Object as PropType<SubmitReadingAnswerResponse>,
            required: true
        }
    }
});
