export function deepCopy(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // If the input is not an object or is null, return it as is
  }

  if (Array.isArray(obj)) {
    // If the input is an array, create a new array and deep copy its elements
    return obj.map((item) => deepCopy(item));
  }

  // If the input is an object, create a new object and deep copy its properties
  const newObj: Record<string, any> = {}; // Specify the type of the new object
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = deepCopy(obj[key]);
    }
  }

  return newObj;
}