import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d2f7c9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.type)
  }, [
    _withDirectives(_createElementVNode("textarea", {
      placeholder: _ctx.$t('word.dialogue') + ' (' + _ctx.targetLang + ')',
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localText) = $event))
    }, null, 8, _hoisted_1), [
      [_vModelText, _ctx.localText]
    ]),
    _withDirectives(_createElementVNode("textarea", {
      placeholder: _ctx.$t('word.translation') +' (' + _ctx.sourceLang + ')',
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localTranslation) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.localTranslation]
    ])
  ], 2))
}