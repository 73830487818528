
import { defineComponent } from "vue";

import {
    getAuthorizationHeader,
    getEndpoint,
    logout
} from '@/utils/auth';
import {
    SearchRequest,
    SearchResponse,
    encodeSearchRequest
} from '@/types';

import Tile from '@/components/Tile.vue';
import FormSearch from '@/components/FormSearch.vue';
import InputPracticeType from '@/components/InputPracticeType.vue';
import Practice from '@/components/Practice.vue';

export default defineComponent({
    name: "PracticeMultipleView",
    components: {
        Tile,
        FormSearch,
        InputPracticeType,
        Practice
    },
    data() {
        return {
            searchRequest: {
                sourceLanguage: localStorage.getItem('sourceLanguage'),
                targetLanguage: localStorage.getItem('targetLanguage'),
                contains: '',
                tags: [],
                readingLevel: '',
                writingLevel: '',
                limit: null,
                offset: 0,
                sortBy: 'Word',
                sortOrder: 'ASC'
            } as SearchRequest,
            searchLoading: false as boolean,
            searchResponse: null as SearchResponse | null,
            words: [] as number[],
            type: 'all',
            practiceStarted: false,
            practiceKey: 0
        }
    },
    methods: {
        search() {
            this.words = [];
            this.searchResponse = null;
            this.searchLoading = true;

            fetch(
                getEndpoint() + '/words?' + encodeSearchRequest(this.searchRequest),
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.searchResponse = await response.json()
                        for (var i = 0; i < this.searchResponse!.words.length; i++) {
                            this.words.push(this.searchResponse!.words[i].id);
                        }
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load words. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load words. Please try again later.');
                }
            ).finally(
                () => {
                    this.searchLoading = false;
                }
            );
        },
        startPractice() {
            this.practiceStarted = true;
            this.practiceKey = (this.practiceKey + 1) % 2;
        }
    }
});
