
import { defineComponent } from "vue";

import Tile from '@/components/Tile.vue';
import FormWord from '@/components/FormWord.vue';

import {
    getAuthorizationHeader,
    getEndpoint,
    logout
} from '@/utils/auth';
import type { Word } from '@/types';

export default defineComponent({
    name: 'WordAddView',
    components: {
        Tile,
        FormWord
    },
    data() {
        return {
            word: {
                id: null,
                sourceLanguage: localStorage.getItem('sourceLanguage'),
                targetLanguage: localStorage.getItem('targetLanguage'),
                known: false,
                word: '',
                meaning: '',
                tags: [this.$t('word.vocabTag')],
                readingLevel: '',
                readingScore: 0,
                writingScore: 0,
                writingLevel: '',
                readingProgress: 0,
                writingProgress: 0,
                mnemonicDevice: '',
                usage: '',
                notes: '',
                examples: []
            } as Word,
            loading: false
        }
    },
    methods: {
        addWord() {
            this.loading = true;

            fetch(
                getEndpoint() + '/words',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.word)
                }
            ).then(
                async response => {
                    if (response.ok) {
                        const resp = await response.json();
                        alert('Successfuly created word.');
                        this.$router.push({name: 'word', params: { id: resp['id'] }});
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error creating word. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error creating word. Please try again later.');
                }
            ).finally(
                () => {
                    this.loading = false;
                }
            );
        }
    }
});
