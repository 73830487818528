import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputDialogue = _resolveComponent("InputDialogue")!
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createBlock(_component_Tile, null, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: "btn-close btn-close-white float-left",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }),
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localExample.scenario) = $event)),
          "data-bs-theme": "dark",
          placeholder: _ctx.$t('word.scenario') + ' (' + _ctx.sourceLang + ')'
        }, null, 8, _hoisted_2), [
          [_vModelText, _ctx.localExample.scenario]
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localExample.dialogue, (dialogue, idx) => {
        return (_openBlock(), _createBlock(_component_InputDialogue, {
          key: dialogue.id,
          dialogue: dialogue,
          onUpdateDialogue: ($event: any) => (_ctx.localExample.dialogue[idx] = $event),
          onClose: ($event: any) => (_ctx.localExample.dialogue.splice(idx, 1))
        }, null, 8, ["dialogue", "onUpdateDialogue", "onClose"]))
      }), 128)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-secondary add-btn",
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addDialogue && _ctx.addDialogue(...args)), ["prevent"]))
        }, "+ " + _toDisplayString(_ctx.$t('notebook.addDialogue')), 1)
      ])
    ]),
    _: 1
  }))
}