
import { 
    defineComponent
} from "vue";

import { logout } from "@/utils/auth";

export default defineComponent({
    name: "Nav",
    methods: {
        logout() {
            logout();
        }
    }
});
