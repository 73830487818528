import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53be1421"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "my-3" }
const _hoisted_4 = { class: "my-3" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tile, {
      title: _ctx.$t('import.import')
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('import.explanation')), 1),
        _createElementVNode("ol", null, [
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('import.example_1')), 1),
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('import.example_2')) + " ", 1),
            _createElementVNode("a", {
              id: "sample-link",
              download: "tangochou_import_sample.tsv",
              href: _ctx.sampleUrl
            }, _toDisplayString(_ctx.$t('import.sample')), 9, _hoisted_2)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("input", {
            class: "form-control",
            "data-bs-theme": "dark",
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeFile && _ctx.changeFile(...args))),
            required: ""
          }, null, 32)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-secondary",
            disabled: _ctx.importLoading,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.importWords && _ctx.importWords(...args)))
          }, _toDisplayString(_ctx.$t('import.importButton')), 9, _hoisted_5)
        ])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}