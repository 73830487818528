
import { 
    PropType,
    defineComponent
} from "vue";
import { GetWritingQuestionResponse, SubmitWritingAnswerRequest } from "@/types";

import { deepCopy } from '@/utils/copy';

import Tile from '@/components/Tile.vue';
import LevelBadge from '@/components/LevelBadge.vue';
import Message from '@/components/Message.vue';
import InputMessage from '@/components/InputMessage.vue';

export default defineComponent({
    Name: 'WritingQuestion',
    components: {
        Tile,
        LevelBadge,
        Message,
        InputMessage
    },
    props: {
        question: {
            type: Object as PropType<GetWritingQuestionResponse>,
            required: true
        },
        answerLoading: {
            type: Boolean,
            required: true
        },
        answered: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            answer: {
                example: deepCopy(this.question.example),
                missingIndex: this.question.missingIndex
            } as SubmitWritingAnswerRequest
        }
    }
});
