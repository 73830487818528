
import { 
    PropType,
    defineComponent
} from "vue";

import LevelBadge from '@/components/LevelBadge.vue';
import { GetWritingQuestionResponse, SubmitWritingAnswerResponse } from "@/types";

export default defineComponent({
    Name: 'WritingAnswer',
    components: {
        LevelBadge
    },
    props: {
        question: {
            type: Object as PropType<GetWritingQuestionResponse>,
            required: true
        },
        answer: {
            type: Object as PropType<SubmitWritingAnswerResponse>,
            required: true
        }
    }
});
