import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LanguageSelectionView from '../views/LanguageSelectionView.vue';
import NotebookView from "../views/NotebookView.vue";
import WordAddView from "../views/WordAddView.vue";
import WordView from '../views/WordView.vue';
import WordEditView from '../views/WordEditView.vue';
import PracticeSingleView from '../views/PracticeSingleView.vue';
import PracticeMultipleView from '../views/PracticeMultipleView.vue';
import ImportView from '../views/ImportView.vue';
import ExportView from '../views/ExportView.vue';
import DeleteView from '../views/DeleteView.vue';
import CallbackView from "../views/CallbackView.vue";

import { login } from "@/utils/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/notebook"
  },
  {
    path: "/language-selection",
    name: 'language-selection',
    component: LanguageSelectionView
  },
  {
    path: "/notebook",
    name: "notebook",
    component: NotebookView
  },
  {
    path: "/word/add",
    name: "word-add",
    component: WordAddView
  },
  {
    path: "/word/:id",
    name: "word",
    component: WordView
  },
  {
    path: "/word/:id/edit",
    name: "word-edit",
    component: WordEditView
  },
  {
    path: "/practice/:id",
    name: "practice-single",
    component: PracticeSingleView
  },
  {
    path: "/practice",
    name: "practice-multiple",
    component: PracticeMultipleView
  },
  {
    path: "/import",
    name: "import",
    component: ImportView
  },
  {
    path: "/export",
    name: "export",
    component: ExportView
  },
  {
    path: "/delete",
    name: "delete",
    component: DeleteView
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Enforce language selection before going to a route.
router.beforeEach((to, from, next) => {
  if (to.name === 'callback') {
    next();
    return;
  }

  // Not authenticated.
  if (localStorage.getItem('authToken') === null) {
    login();
    return;
  }

  // Source and target language aren't defined.
  if (localStorage.getItem('sourceLanguage') === null && to.name != 'language-selection') {
    next({'name': 'language-selection'});
    return;
  }  

  // Okay.
  next();
});

export default router;
