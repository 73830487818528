
import { defineComponent } from "vue";

import Tile from '@/components/Tile.vue';
import FormSearch from '@/components/FormSearch.vue';
import { SearchRequest, SearchResponse, encodeSearchRequest } from "@/types";
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: 'DeleteView',
    components: {
        Tile,
        FormSearch,
    },
    data() {
        return {
            searchRequest: {
                sourceLanguage: localStorage.getItem('sourceLanguage'),
                targetLanguage: localStorage.getItem('targetLanguage'),
                contains: '',
                tags: [],
                readingLevel: '',
                writingLevel: '',
                limit: null,
                offset: 0,
                sortBy: 'Word',
                sortOrder: 'ASC'
            } as SearchRequest,
            searchLoading: false as boolean,
            searchResponse: null as SearchResponse | null,
            words: [] as number[],
            deleteLoading: false
        }
    },
    methods: {
        search() {
            this.words = [];
            this.searchResponse = null;
            this.searchLoading = true;

            fetch(
                getEndpoint() + '/words?' + encodeSearchRequest(this.searchRequest),
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.searchResponse = await response.json()
                        for (var i = 0; i < this.searchResponse!.words.length; i++) {
                            this.words.push(this.searchResponse!.words[i].id);
                        }
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load words. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load words. Please try again later.');
                }
            ).finally(
                () => {
                    this.searchLoading = false;
                }
            );
        },
        deleteWords() {
            if (!confirm(this.$t('delete.confirmation'))) {
                return;
            }

            this.deleteLoading = true;

            fetch(
                getEndpoint() + '/words/delete',
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.words)
                }
            ).then(
                async response => {
                    if (response.ok) {
                        alert('Successfully deleted.');
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error deleting. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error deleting. Please try again later.');
                }
            ).finally(
                () => {
                    this.deleteLoading = false;
                }
            )
        }
    }
});
