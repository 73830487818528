
import { defineComponent } from "vue";

import Tile from '@/components/Tile.vue';
import Example from '@/components/Example.vue';
import LevelBadge from '@/components/LevelBadge.vue';

import type { Word } from '@/types';
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: "WordView",
    components: {
        Tile,
        Example,
        LevelBadge
    },
    created() {
        this.getWord();
    },
    data() {
        return {
            word: null as Word | null,
            getLoading: false,
            deleteLoading: false
        }
    },
    methods: {
        getWord() {
            this.getLoading = true;

            fetch(
                getEndpoint() + '/words/' + this.$route.params.id,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.word = await response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load word. Please try again later.');
                        this.$router.push({name: 'notebook'})
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load word. Please try again later.');
                    this.$router.push({name: 'notebook'});
                }
            ).finally(
                () => {
                    this.getLoading = false;
                }
            )
        },
        deleteWord() {
            if (!confirm('Are you sure you want to delete this?')) {
                return;
            }

            this.deleteLoading = true;

            fetch(
                getEndpoint() + '/words/' + this.$route.params.id,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        alert('Successfully deleted word.');
                        this.$router.push({name: 'notebook'}) 
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error deleting word. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error deleting word. Please try again later.');
                }
            ).finally(
                () => {
                    this.deleteLoading = false;
                }
            )
        }
    }
});
