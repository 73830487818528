
import { 
    PropType,
    defineComponent
} from "vue";
import InputExample from '@/components/InputExample.vue';

import { v4 as uuidv4 } from 'uuid';
import $ from "jquery";
import { deepCopy } from '@/utils/copy';
import { encodeListTagsRequest, type Word } from '@/types';
import type { ListTagsRequest } from '@/types';
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: "FormWord",
    components: {
        InputExample
    },
    created() {
        this.listTags();
    },
    mounted() {
        $('#form-word-tag-select').select2({
            tags: true
        });
        $('#form-word-tag-select').on('change.select2', this.updateTags);
    },
    props: {
        word: {
            type: Object as PropType<Word>,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            sourceLang: localStorage.getItem('sourceLanguage'),
            targetLang: localStorage.getItem('targetLanguage'),
            tags: [],
            localWord: deepCopy(this.word) as Word,
            generateExampleLoading: false
        };
    },
    computed: {
        wordType: {
            get(): string {
                if (this.localWord.tags.includes(this.$t('word.vocabTag'))) {
                    return this.$t('word.vocabTag');
                } else if (this.localWord.tags.includes(this.$t('word.grammarTag'))) {
                    return this.$t('word.grammarTag');
                } else {
                    return '';
                }
            },
            set(newWordType: string) {
                if (newWordType === this.$t('word.vocabTag')) {
                    this.localWord.tags = this.localWord.tags.filter(tag => tag !== this.$t('word.grammarTag'));
                    if (!this.localWord.tags.includes(this.$t('word.vocabTag'))) {
                        this.localWord.tags.push(this.$t('word.vocabTag'));
                    }
                } else if (newWordType === this.$t('word.grammarTag')) {
                    this.localWord.tags = this.localWord.tags.filter(tag => tag !== this.$t('word.vocabTag'));
                    if (!this.localWord.tags.includes(this.$t('word.grammarTag'))) {
                        this.localWord.tags.push(this.$t('word.grammarTag'));
                    }
                }
            }
        },
        tagOptions: {
            get(): string[] {
                return this.tags.filter(tag => tag !== this.$t('word.grammarTag') && tag !== this.$t('word.vocabTag'));
            },
            set(newTagOptions: string) {}
        }
    },
    watch: {
        localWord: {
            handler(val) {
                this.$emit('updateWord', val);    
            },
            deep: true
        }
    },
    methods: {
        listTags() {
            fetch(
                getEndpoint() + '/tags?' + encodeListTagsRequest(
                    {
                        sourceLanguage: this.sourceLang,
                        targetLanguage: this.targetLang
                    } as ListTagsRequest
                ),
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.tags = await response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load tags. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load tags. Please try again later.');
                }
            )
        },
        addExample() {
            this.localWord.examples.push({
                id: uuidv4(),
                scenario: '',
                dialogue: []
            });
        },
        generateExample() {
            this.generateExampleLoading = true;

            fetch(
                getEndpoint() + '/words/generate_example',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            word: this.localWord.word,
                            meaning: this.localWord.meaning,
                            sourceLanguage: this.sourceLang,
                            targetLanguage: this.targetLang
                        }
                    )
                }
            ).then(
                async response => {
                    if (response.ok) {
                        const example = await response.json();
                        this.localWord.examples.push(example);
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error generating example. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error generating example. Please try again later.');
                }
            ).finally(
                () => {
                    this.generateExampleLoading = false;
                }
            )
        },
        updateTags(event: any) {
            let newTags = this.localWord.tags.filter(tag => tag === this.$t('word.vocabTag') || tag === this.$t('word.grammarTag'));
            newTags.push(...Array.from(event.target.selectedOptions, (option: HTMLOptionElement) => option.value));
            this.localWord.tags = newTags;
        },
    }
});
