
import { 
    PropType,
    defineComponent
} from "vue";
import Tile from '@/components/Tile.vue';
import Message from '@/components/Message.vue';

import type { Dialogue } from '@/types';

export default defineComponent({
    name: 'Dialogue',
    components: {
        Tile,
        Message
    },
    props: {
        dialogue: Object as PropType<Dialogue>
    }
})
