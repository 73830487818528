
import { defineComponent } from "vue";

export default defineComponent({
    name: "InputMessage",
    props: {
        type: String,
        text: String,
        translation: String
    },
    data() {
        return {
            sourceLang: localStorage.getItem('sourceLanguage'),
            targetLang: localStorage.getItem('targetLanguage'),
            localText: this.text,
            localTranslation: this.translation
        }
    },
    watch: {
        localText: {
            handler(val) {
                this.$emit('updateText', val);
            },
            deep: true
        },
        localTranslation: {
            handler(val) {
                this.$emit('updateTranslation', val);
            },
            deep: true
        }
    }
});
