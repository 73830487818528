
import { 
    PropType,
    defineComponent
} from "vue";

import ReadingQuestion from '@/components/ReadingQuestion.vue';
import WritingQuestion from '@/components/WritingQuestion.vue';
import ReadingAnswer from '@/components/ReadingAnswer.vue';
import WritingAnswer from '@/components/WritingAnswer.vue';

import type {
Question,
GetReadingQuestionResponse,
GetWritingQuestionResponse,
SubmitReadingAnswerRequest,
SubmitWritingAnswerRequest,
SubmitReadingAnswerResponse,
SubmitWritingAnswerResponse
} from '@/types';
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: "Practice",
    components: {
        ReadingQuestion,
        WritingQuestion,
        ReadingAnswer,
        WritingAnswer
    },
    props: {
        words: {
            type: Array as PropType<Array<Number>>,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    created() {
        if (this.words.length === 0) {
            this.noQuestionsMessage = 'No words selected.';
            return;
        }
        this.fillQuestionPool();
        this.getQuestion();
    },
    data() {
        return {
            questionPool: [] as Question[],
            noQuestionsMessage: '',
            getQuestionLoading: false,
            currQuestion: null as null | GetReadingQuestionResponse | GetWritingQuestionResponse,
            submitAnswerLoading: false,
            currAnswer: null as null | SubmitReadingAnswerResponse | SubmitWritingAnswerResponse,
            saveExampleLoading: false,
            saveExampleDisabled: false
        }
    },
    methods: {
        fillQuestionPool() {
            // Empty the pool.
            this.questionPool = [];

            // Put questions into the pool.
            for (var i = 0; i < this.words.length; i++) {
                if (this.type === 'all') {
                    this.questionPool.push({
                        word: this.words[i],
                        type: 'reading'
                    } as Question);
                    this.questionPool.push({
                        word: this.words[i],
                        type: 'writing'
                    } as Question);
                } else {
                    this.questionPool.push({
                        word: this.words[i],
                        type: this.type
                    } as Question);
                }
            }

            // Shuffle the pool.
            // https://stackoverflow.com/a/54608401
            let currentIndex = this.questionPool.length,  randomIndex;
            while (currentIndex > 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [this.questionPool[currentIndex], this.questionPool[randomIndex]] = [
                    this.questionPool[randomIndex], this.questionPool[currentIndex]
                ];
            }
        },
        getQuestion() {
            const question = this.questionPool[0];

            this.getQuestionLoading = true;
            this.currQuestion = null;
            this.currAnswer = null;
            this.submitAnswerLoading = false;
            this.saveExampleLoading = false;
            this.saveExampleDisabled = false;

            fetch(
                getEndpoint() + '/question/' + question.word + '/' + question.type,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.currQuestion = await response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error getting question. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error getting question. Please try again later');
                }
            ).finally(
                () => {
                    this.getQuestionLoading = false;
                }
            )
        },
        submitAnswer(req: SubmitReadingAnswerRequest | SubmitWritingAnswerRequest) {
            const question = this.questionPool[0];
            
            this.submitAnswerLoading = true;
            this.currAnswer = null;

            fetch(
                getEndpoint() + '/answer/' + question.word + '/' + question.type,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(req)
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.currAnswer = await response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error submitting answer. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error submitting answer. Please try again later.');
                }
            ).finally(
                () => {
                    this.submitAnswerLoading = false;
                }
            )
        },
        nextQuestion() {
            this.questionPool.shift();

            if (this.questionPool.length === 0) {
                this.fillQuestionPool();
            }

            this.getQuestion();
        },
        saveExample() {
            this.saveExampleLoading = true;

            let example = this.currQuestion!.example;
            if (this.currAnswer !== null && 'example' in this.currAnswer) {
                example = this.currAnswer.example;
            }

            fetch(
                getEndpoint() + '/words/' + this.currQuestion!.word.id + '/examples',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(example)
                }
            ).then(
                async response => {
                    if (response.ok) {
                        alert('Successfuly saved example.');
                        this.saveExampleDisabled = true;
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error saving example. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error saving example. Please try again later.');
                }
            ).finally(
                () => {
                    this.saveExampleLoading = false;
                }
            )
        }
    }
});
