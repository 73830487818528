
import { defineComponent } from "vue";

import Tile from '@/components/Tile.vue';
import FormWord from '@/components/FormWord.vue';

import type { Word } from "@/types";
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: 'WordEditView',
    components: {
        Tile,
        FormWord
    },
    created() {
        this.getWord();
    },
    data() {
        return {
            word: null as Word | null,
            getLoading: false,
            updateLoading: false
        }
    },
    methods: {
        getWord() {
            this.getLoading = true;

            fetch(
                getEndpoint() + '/words/' + this.$route.params.id,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.word = await response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load word. Please try again later.');
                        this.$router.push({name: 'notebook'})
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load word. Please try again later.');
                    this.$router.push({name: 'notebook'});
                }
            ).finally(
                () => {
                    this.getLoading = false;
                }
            )
        },
        updateWord() {
            this.updateLoading = false;

            fetch(
                getEndpoint() + '/words/' + this.$route.params.id,
                {
                    method: 'PUT',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.word)
                }
            ).then(
                async response => {
                    if (response.ok) {
                        alert('Successfuly updated word.');
                        this.$router.push({name: 'word', params: { id: this.$route.params.id }});
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error updating word. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error updating word. Please try again later.');
                }
            ).finally(
                () => {
                    this.updateLoading = false;
                }
            );
        }
    }
});
