
import {
    PropType,
    defineComponent
} from "vue";
import Tile from '@/components/Tile.vue';
import Dialogue from '@/components/Dialogue.vue';

import type { Example } from '@/types';

export default defineComponent({
    name: 'Example',
    components: {
        Tile,
        Dialogue
    },
    props: {
        example: Object as PropType<Example>
    }
})
