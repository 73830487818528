
import { 
    PropType,
    defineComponent
} from "vue";

import $ from "jquery";
import {deepCopy} from '@/utils/copy';
import {
    encodeListTagsRequest,
    ListTagsRequest,
    SearchRequest
} from '@/types';
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: "FormSearch",
    created() {
        this.listTags();
    },
    mounted() {
        $('#form-search-tag-select').select2({
            tags: true
        });
        $('#form-search-tag-select').on('change.select2', this.updateTags);
    },
    props: {
        searchRequest:  {
            type: Object as PropType<SearchRequest>,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        hideSort: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            tags: [] as string[],
            localSearchRequest: deepCopy(this.searchRequest) as SearchRequest
        };
    },
    watch: {
        localSearchRequest: {
            handler(val) {
                this.$emit('updateSearchRequest', val);
            },
            deep: true
        }
    },
    methods: {
        listTags() {
            fetch(
                getEndpoint() + '/tags?' + encodeListTagsRequest(
                    {
                        sourceLanguage: localStorage.getItem('sourceLanguage'),
                        targetLanguage: localStorage.getItem('targetLanguage')
                    } as ListTagsRequest
                ),
                {
                    method: 'GET',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(
                async response => {
                    if (response.ok) {
                        this.tags = await response.json();
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Unable to load tags. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Unable to load tags. Please try again later.');
                }
            )
        },
        updateTags(event: any) {
            this.localSearchRequest.tags = Array.from(event.target.selectedOptions, (option: HTMLOptionElement) => option.value);
        },
    }
});
