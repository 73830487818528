
import { defineComponent } from "vue";

export default defineComponent({
    name: "InputPracticeType",
    props: {
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            localType: this.type
        }
    },
    watch: {
        localType: {
            handler(val) {
                this.$emit('updateType', val);
            },
            deep: true
        }
    },
});
