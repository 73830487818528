import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormWord = _resolveComponent("FormWord")!
  const _component_Tile = _resolveComponent("Tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tile, {
      title: _ctx.$t('notebook.add')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormWord, {
          word: _ctx.word,
          loading: _ctx.loading,
          onUpdateWord: _cache[0] || (_cache[0] = ($event: any) => (_ctx.word = $event)),
          onSubmit: _withModifiers(_ctx.addWord, ["prevent"])
        }, null, 8, ["word", "loading", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}