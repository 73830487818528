
import {
    PropType,
    defineComponent
} from "vue";
import Tile from '@/components/Tile.vue';
import InputDialogue from '@/components/InputDialogue.vue';

import { v4 as uuidv4 } from 'uuid';

import { deepCopy } from '@/utils/copy';
import type { Example } from '@/types';

export default defineComponent({
    name: "InputExample",
    components: {
        Tile,
        InputDialogue
    },
    props: {
        example: Object as PropType<Example>
    },
    data() {
        return {
            sourceLang: localStorage.getItem('sourceLanguage'),
            targetLang: localStorage.getItem('targetLanguage'),
            localExample: deepCopy(this.example) as Example
        }
    },
    watch: {
        localExample: {
            handler(val) {
                this.$emit('updateExample', val);    
            },
            deep: true
        }
    },
    methods: {
        addDialogue() {
            this.localExample.dialogue.push({
                id: uuidv4(),
                name: '',
                portrait: 's_1',
                type: 'speech',
                text: '',
                translation: ''
            });
        }
    }
});
