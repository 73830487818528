
import { defineComponent } from "vue";

import Tile from '@/components/Tile.vue';
import { getAuthorizationHeader, getEndpoint, logout } from "@/utils/auth";

export default defineComponent({
    name: 'ImportView',
    components: {
        Tile
    },
    data() {
        return {
            sampleUrl: process.env.BASE_URL + 'tangochou_import_sample.tsv',
            importLoading: false,
            importFile: null as null | Blob
        }
    },
    methods: {
        changeFile(event: any) {
            this.importFile = event.target.files[0];
        },
        importWords() {
            if (!this.importFile) {
                return;
            }

            this.importLoading = true;

            const formData = new FormData();
            formData.append('file', this.importFile);

            fetch(
                getEndpoint() + '/words/import?sourceLanguage=' + localStorage.getItem('sourceLanguage') + '&targetLanguage=' + localStorage.getItem('targetLanguage'),
                {
                    method: 'POST',
                    headers: {
                        'Authorization': getAuthorizationHeader(),
                    },
                    body: formData
                }
            ).then(
                async response => {
                    if (response.ok) {
                        alert('Successfully imported.');
                    } else if (response.status === 401) {
                        alert('Your session has expired.');
                        logout();
                    } else {
                        alert('Error importing. Please try again later.');
                    }
                }
            ).catch(
                error => {
                    alert('Error importing. Please try again later.');
                }
            ).finally(
                () => {
                    this.importLoading = false;
                }
            );
        }
    }
});
